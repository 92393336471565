<template>
	<div class="courseContentItemWrapper">
		<div class="courseContentItem" @click="openVideo(courseItem, $event)">
			<!-- 0 目录；1，视频；2，音频；3，文档；4，图片；6，scorm；9，试卷；10，练习；11，直播；12，实训 -->
			<div class="courseContentItem_left">
				<!-- 视频 -->
				<template v-if="courseItem.courseType === 1">
					<svg-icon icon-class="study_mp4"></svg-icon>
				</template>
				<!-- 图片 -->
				<template v-else-if="courseItem.courseType === 4">
					<svg-icon icon-class="study_img"></svg-icon>
				</template>
				<!-- 音频 -->
				<template v-else-if="courseItem.courseType === 2">
					<svg-icon icon-class="study_mp3"></svg-icon>
				</template>
				<!-- 文档 -->
				<template v-else-if="courseItem.courseType === 3">
					<svg-icon icon-class="study_doc"></svg-icon>
				</template>
				<!-- 练习 -->
				<template v-else-if="courseItem.courseType === 10">
					<svg-icon icon-class="lianxi_gray" style="color:#666"></svg-icon>
				</template>
				<span>{{ courseItem.contentName }}</span>
			</div>
			<div class="courseContentItem_right">
				<span class="signUp" v-if="!this.detailObj.isOrder && !canTryWatch">需要报名</span>

				<span class="tryVideo" v-if="!this.detailObj.isOrder && !detailObj.isFree && canTryWatch">
					<svg-icon icon-class="play"></svg-icon>
					<span>可试看</span></span>

				<!-- <span class="lock" v-else-if="isOverType != 'oks' && (!canTryWatch || detailObj.isFree)">
                    <svg-icon icon-class="lock"></svg-icon>
                </span> -->
			</div>
		</div>
		<el-dialog :visible.sync="dialogVisible1" :close-on-click-modal="false" width="1040px" top="6vh" :before-close="handleClose2">
			<el-dialog :visible.sync="innerVisible" width="400px" top="25vh" :close-on-click-modal="false" custom-class="tipOver" append-to-body>
				<div class="overVideo">
					<svg-icon icon-class="tips"></svg-icon>
					<div class="tryOver">试看结束</div>
					<div class="tryBottom">购买后可查看完整内容</div>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="innerVisible = false">取消</el-button>
					<el-button type="warning" @click="toPay()">去报名</el-button>
				</div>
			</el-dialog>
      <tcvideoSaas v-if="videoSource == '555'" ref="tcvideoSaas2" @openIt="open" :idName="courseItem ? courseItem.id : ''" :isOrder="detailObj.isOrder" />
			<videoSaas v-else ref="videoSaas2" @openIt="open" :idName="courseItem ? courseItem.id : ''" :isOrder="detailObj.isOrder" />
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible2" :close-on-click-modal="false" width="1040px" top="6vh" :before-close="handleClose">
			<div id="Vidpreview"></div>
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible3" :close-on-click-modal="false" width="1040px" top="6vh" :before-close="handleClose">
			<img :src="imgUrl" style="width:100%" />
		</el-dialog>
	</div>
</template>

<script>
import videoSaas from './video.vue'
import tcvideoSaas from './tcvideo.vue'
export default {
	name: 'courseContentItem',
	data() {
		return {
			innerVisible: false,
			dialogVisible1: false,
			dialogVisible2: false,
			dialogVisible3: false,
			imgUrl: '',
			canTryWatch: false,
			validity: '', // 是否显示有效期
      videoSource: '',
      videoPlayChannel: '',
			isDongkun:'false'
		}
	},
	props: {
		courseItem: Object,
		className: String,
		currentCourse: Object,
		isFaceRecognize: Number,
		taskId: Number,
		// 商品详情
		detailObj: {
			type: Object,
			default: () => {},
		},
		//是否过期
		isOverType: {
			type: String,
			default: 'no',
		},
	},
	components: { videoSaas, tcvideoSaas },
	mounted() { 
		const configurationArr = JSON.parse(localStorage.getItem('configurationArr'))
		// 是否显示训练营，任务，测评 有效期
		let is_show_task_term_validity =
			configurationArr &&
			configurationArr.filter((item) => {
				if (item.key === 'is_show_task_term_validity') {
					return true
				}
			})[0]
      let video_play_channel = configurationArr && configurationArr.filter(item => {
        if(item.key === 'video_play_channel'){
          return true
        }
      })[0];
		this.validity = is_show_task_term_validity && is_show_task_term_validity.value
    this.videoPlayChannel = video_play_channel && video_play_channel.value
		var isdongkun = JSON.parse(localStorage.getItem('configurationArr')).filter((item)=>{
				return item.key == 'is_dongkun_empower';
			})
			this.isDongkun = isdongkun[0].value;
		// 是否可以试看
		if (this.detailObj.tryWatchList) {
			this.canTryWatch = this.detailObj.tryWatchList.some((item) => {
				return item.itemId === this.courseItem.id
			})
		}
		// console.log('mounted---------------------', this.courseItem)
	},
	methods: {
		//试看结束弹窗
		open() {
			this.innerVisible = true
		},
		showPreviewPlayer(row, tryWatchLength) {
			var params = ''
			if (row.courseType < 3) {
				this.dialogVisible1 = true
        if (this.videoPlayChannel === 'tencent' && row.courseType == 1) {
          params = {
            resId: row.detailId
          }
          this.$api.resource.getPlayAuthTokenInfo({ params }).then(res => {
            if (res.success && res.data) {
              this.videoSource = '555'
              this.$nextTick(() => {
                if(res.data?.sourceSing=='555') {
                  this.$refs.tcvideoSaas2.getTcplayer(res.data, tryWatchLength, row.courseId, row.duration)
                } else {
                  const vi = Object.assign({}, res.data)
                  vi.tencentPlayVideoResp = { playUrl: vi.playUrl }
                  this.$refs.tcvideoSaas2.getTcplayer(vi, tryWatchLength, row.courseId, row.duration)
                }
              })
              this.dialogVisible1 = true
            }
          })
        } else {
          this.$api.openTryWatch.getPlayAuthToken(row).then((res) => {
            if (res.success && res.data) {
              // 音频
              if (row.courseType === 2) {
                params = {
                  format: 'mp3',
                  mediaType: 'audio',
                  watchPercent: tryWatchLength,
                }
              } else if (row.courseType === 1) {
                params = {
                  format: 'm3u8',
                  mediaType: 'video',
                  watchPercent: tryWatchLength,
                }
              }
              this.videoSource = res.data?.sourceSing
              this.$nextTick(() => {
                if(res.data?.sourceSing){
                  if (res.data?.sourceSing=='222' || res.data?.sourceSing=='333') {
                    // 三方，222 格莱森的，333交通的
                    this.$refs.videoSaas2.initPlayer(res.data.playUrl,true,{watchPercent: tryWatchLength,duration:row.duration})
                  } else if (res.data?.sourceSing=='555') {
                    this.$refs.tcvideoSaas2.getTcplayer(res.data, params, row.courseId, row.duration)
                  } else {
                    this.$refs.videoSaas2.initPlayer(res.data,false, params)
                  }
                }else{
                  this.$refs.videoSaas2.initPlayer(res.data,false, params)
                }
              })
              this.dialogVisible1 = true
            }
          })
        }
			} else if (row.courseType === 4) {
				this.$api.openTryWatch.getImageOrDocUrl(row).then((res) => {
					if (res.data) {
						this.imgUrl = res.data
						this.dialogVisible3 = true
					}
				})
			} else if (row.courseType === 3) {
				this.$api.openTryWatch.getDocumentAccessToken(row).then((res) => {
					if (res.data) {
						// 下边是安全才有的
						this.dialogVisible2 = true
						setTimeout(async () => {
							// eslint-disable-next-line no-undef
							let demo = aliyun.config({
								mount: document.querySelector('#Vidpreview'),
								url: res.data.previewURL, //设置文档预览URL地址。
							})
							demo.setToken({ token: res.data.accessToken })
							// ppt存在动画才有，否则会报错
							if (row.name.split('.')[1] === 'ppt') {
								demo.PPTApplication().ActivePresentation.SlideShowSettings.Run()
								demo.PPTApplication().ActivePresentation.Slides.Count
							}
						}, 100)
					}
				})
			} else if (row.courseType === 6 || row.courseType === 9 || row.courseType === 10 || row.courseType === 11 || row.courseType === 12) {
				this.$message({
					message: '请点击立即学习进入',
					type: 'warning',
				})
			}
		},
		//不可试看提示
		noTry() {
			if (this.isOverType == 'no1') {
				this.$message({
					message: '课程未开始！',
					type: 'warning',
				})
			} else if (this.isOverType == 'no2') {
				this.$message({
					message: '课程已过期！',
					type: 'warning',
				})
			} else {
				this.$message({
					message: '报名后才可以观看！',
					type: 'warning',
				})
			}
		},
		toPay() {
			if(this.detailObj.signUpEndTime && this.detailObj.signUpStartTime) {
				if(new Date() - new Date(this.detailObj.signUpEndTime) > 0 ) {
					this.$message({
						message: '报名已过期',
						type: 'warning'

					})
					return 
					} else if((new Date() - new Date(this.detailObj.signUpStartTime)) < 0){
					this.$message({
						message: '报名未开始',
						type: 'warning'
					})
					return 
				}
			}
			if (localStorage.getItem('token')) {
				var obj = {
					goodsId: this.detailObj.id,
					orderSource: 1,
					orderType: this.$route.query.goodsKind*1 == 2 ? 4 : null, //4渠道分销(订单类型)
				}
				this.$api.courseGoodsDetail.order(obj).then((res) => {
					if (res.code == 0) {
						if (res.data.placeOrderSuccess && res.data.realPayPrice > 0) {
							this.$router.push(`/orderPage?orderNo=${res.data.orderNo}&dymode=1`)
						} else if (res.data.placeOrderSuccess && res.data.realPayPrice === 0) {
							//
							this.$message({
								message: '报名成功',
								type: 'success',
							})
							this.detailObj.isOrder = 1
						} else {
							if (res.data.url) {
								window.open(res.data.url, '_self')
							} else {
								this.$router.go(-1)
							}
						}
					}
				})
			} else {
				//未登录转登录
				this.$router.push({
					path: '/login',
					query: {
						name: 'courseGoodsDetail',
						goodsId: this.detailObj.id,
						orderType: this.$route.query.goodsKind*1 == 2 ? 4 : null, //1 普通商品 2 分销商品
					},
				})
			}
		},
		//关闭图片以及文档弹出层
		handleClose() {
			this.dialogVisible2 = false
			this.imgUrl = ''
			this.dialogVisible3 = false
		},
		//关闭视频试看弹窗
		handleClose2() {
			this.dialogVisible1 = false
      if (this.$refs.videoSaas2) {
        this.$refs.videoSaas2.dispose()
      }
      if (this.$refs.tcvideoSaas2) {
        this.$refs.tcvideoSaas2.dispose()
      }
		},
		//打开试看弹框
		openVideo(data) {
			if (this.detailObj.isOrder && this.isOverType == 'oks') {
				if (this.detailObj.goodsType == 1) {
					this.$router.push({
						path: '/course/catalog',
						query: {
							taskId: this.detailObj.referId, //训练营id
							taskType: this.detailObj.goodsType,
							goodsId: this.$route.query.goodsId,
							goodsKind:this.$route.query.goodsKind,
							backPath: this.$route.path,
						},
					})
				} else if (this.detailObj.goodsType == 2) {
					// this.$router.push(`/course/catalog?id=${this.detailObj.referId}&dymode=1`)
					this.$router.push({
						path: '/learning/taskDetail',
						query: {
							id: this.detailObj.referId, //培训任务id
							goodsId: this.$route.query.goodsId,
							goodsKind:this.$route.query.goodsKind,
							backPath: this.$route.path,
							type: this.detailObj.goodsType,
							validity: this.validity || '',
							isNeedBreadcrumb:this.$route.query.isNeedBreadcrumb
						},
					})
				} else if (this.detailObj.goodsType == 3) {
					this.$router.push({
						path: '/assess',
						query: {
							assessId: this.detailObj.referId, //测评id
							refId: this.$route.query.goodsId,
							refType: 2,
							goodsId: this.$route.query.goodsId,
							goodsKind:this.$route.query.goodsKind,
							pathName: this.$route.path,
						},
					})
				}
			} else {
				if (!this.detailObj.isFree && this.canTryWatch) {
					let tryWatchObj = this.detailObj.tryWatchList.find((item) => {
						return item.itemId === data.id
					})
					let tryWatchLength = tryWatchObj && tryWatchObj.value
					// console.log(data.id,this.detailObj.tryWatchList,tryWatchObj,tryWatchLength,'=============')
					this.showPreviewPlayer(data, tryWatchLength)
				} else {
					this.noTry()
				}
			}
		},
	},
}
</script>

<style lang="stylus" scoped src="../css/courseContentItem.styl"></style>
