<template>
	<div>
		<div class="taskItem taskItem0" v-for="taskItem in taskCatalogList" :key="taskItem.id">
			<!-- 培训任务没有目录 -->
			<template v-if="!hasTaskCatalog(taskItem)">
				<TaskContentItem
					:taskItem="taskItem"
					:detailObj="detailObj"
					:isOverType="isOverType"
					:defaultUnfoldCourse="defaultUnfoldCourse"
				></TaskContentItem>
			</template>

			<!-- 一级目录 -->
			<template v-else>
				<el-collapse class="taskCollapse" :class="{ locked: taskItem.isLocked }" v-model="taskActiveNamea" accordion>
					<el-collapse-item :name="taskItem.id" :disabled="taskItem.isLocked">
						<template #title>
							<div class="taskTitle" @click="handleClickCatalog(taskItem)">
								<span>{{ taskItem.stageName }}</span>
								<svg-icon v-if="taskItem.isLocked" icon-class="lock"></svg-icon>
							</div>
						</template>

						<div class="taskItem taskItem1" v-for="item1 in taskItem.childItemList" :key="item1.id">
							<template v-if="!hasTaskCatalog(item1)">
								<TaskContentItem
									:taskItem="item1"
									:detailObj="detailObj"
									:isOverType="isOverType"
									:defaultUnfoldCourse="defaultUnfoldCourse"
									:className="'innerTaskCatalog'"
								></TaskContentItem>
							</template>

							<!-- 二级目录 -->
							<template v-else>
								<el-collapse class="taskCollapse taskCollapse2" v-model="taskActiveNameaa" accordion>
									<el-collapse-item :name="item1.id" :disabled="item1.isLocked">
										<template #title>
											<div class="taskTitle taskTitle2" @click="handleClickCatalog(item1)">
												<span>{{ item1.stageName }}</span>
												<svg-icon v-if="item1.isLocked" icon-class="lock"></svg-icon>
											</div>
										</template>

										<div class="taskItem taskItem2" v-for="item2 in item1.childItemList" :key="item2.id">
											<TaskContentItem
												:taskItem="item2"
												:detailObj="detailObj"
												:isOverType="isOverType"
												:defaultUnfoldCourse="defaultUnfoldCourse"
												:className="'innerTaskCatalog'"
											></TaskContentItem>
										</div>
									</el-collapse-item>
								</el-collapse>
							</template>
						</div>
					</el-collapse-item>
				</el-collapse>
			</template>
		</div>
	</div>
</template>

<script>
import TaskContentItem from './taskContentItem'
export default {
	name: 'taskItem',
	data() {
		return {
			taskActiveNamea: '',
			taskActiveNameaa: '',
			defaultUnfoldCourse: '', //默认展开课程id
		}
	},
	props: {
		taskCatalogList: Array,
		//当前层级对象
		itemObj: {
			type: Object,
			default: () => {},
		},
		//商品ID
		goodsId: {
			type: Number,
			default: 1,
		},
		//是否过期
		isOverType: {
			type: String,
			default: 'no',
		},
		// 商品详情
		detailObj: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		TaskContentItem,
	},
	mounted() {
		// console.log(this.detailObj, 'detailobJ-------------------')
		let catalog1 = this.taskCatalogList[0]
		if (catalog1.type == 1) {//培训任务目录
			let catalog2 = catalog1.childItemList[0]
			if (catalog2.type == 1) {
                this.taskActiveNamea = catalog1.id
				//二级目录
				this.taskActiveNameaa = catalog2.id
				this.defaultUnfoldCourse = catalog2.childItemList[0].id
			} else {
				//一级目录
				this.taskActiveNamea = catalog1.id
				this.defaultUnfoldCourse = catalog1.childItemList[0].id
			}
		} else if(catalog1.type == 2){//课程
            this.defaultUnfoldCourse = catalog1.id
        }
	},
	methods: {
		// 有无培训目录
		hasTaskCatalog(taskItem) {
			if (taskItem.type === 1) {
				return true
			} else {
				return false
			}
        },
        handleClickCatalog(){
            
        }
	},
}
</script>

<style lang="stylus" scoped>
@import '../css/taskItem.styl'
</style>
