<template>
  <div class="onlyCourseGoodsBg">
    <div class="courseGoodsCont" ref="courseGoodsContRef">
      <div class="goodsSearchCont"  ref="searchBtnRef" v-if="isShowSearchBox">
        <div class="searchResultTips fl" v-if="isShowSearchResult">共有{{paging.total}}个包"{{goodsKey}}"的课程</div>
        <div class="searchIptBox fr">
          <!-- <input type="text" ref="searchInp" class="searchIpt" v-model="goodsKey" v-on:keyup.enter="getData('searchBtn')" placeholder="请输入关键字"/>
          <span class="searchBtn"  @click="getData('searchBtn')">
            <svg-icon icon-class="search"></svg-icon>
          </span> -->
          <el-input class="search" type="text" ref="searchInp" placeholder="请输入关键字" v-model="goodsKey">
            <el-button class="btn" slot="append" icon="el-icon-search" @click="getData('searchBtn')"></el-button>
          </el-input>
        </div>
      </div>
      <div class="classifyArea">
        <div class="courseClassifyItem" v-if="isShowClassifyBox">
            <div class="courseClassifyTitle">课程分类:</div>
            <div class="courseClassifyItemListCont">
                <ul class="courseClassifyItemList firstCourseClassifyItemList" >
                  <li v-for="(item, idx) in  firstCourseClassify" :key="idx">
                    <span v-if="item.showTag" :class="{active: item.isActive }"  @click="clickFirstClassifyHandle(idx, item)">{{item.name}}</span>
                  </li>
                </ul>
                 <ul class="courseClassifyItemList marginTop16"   :class="{borderTopStyle: secondCourseClassify && secondCourseClassify}" v-if="secondCourseClassify.length">
                   <li v-for="(item, idx) in  secondCourseClassify" :key="idx">
                    <span v-if="item.showTag" :class="{active: item.isActive }"  @click="clickSecondClassifyHandle(idx, item)">{{item.name}}</span>
                   </li>
                 </ul>
                 <ul class="courseClassifyItemList marginTop16 threeClassifyStyle"  v-if="threeCourseClassify.length">
                   <li v-for="(item, idx) in  threeCourseClassify" :key="idx">
                    <span v-if="item.showTag" :class="{active: item.isActive }"  @click="clickThreeClassifyHandle(idx, item)">{{item.name}}</span>
                   </li>
                 </ul>   
            </div>
        </div>
        <div class="comprehensiveSortItem"  v-if="isShowClassifyBox">
            <div class="comprehensiveSortItemTitle">综合排序:</div>
            <div class="comprehensiveSortItemListCont">
                <ul class="comprehensiveSortItemList">
                  <li  v-for="(item, idx) in  typeList" :key="idx">
                    <span  :class="{active: item.isActive }" @click="clickTypeSortHandle(idx, item)">{{item.name}}</span>
                  </li>
                </ul>
            </div>    
        </div>    
      </div>
      <div class="courseGoodsListCont">
        <div class="configTile" v-if="isShowTitlechBox">
          <font v-if="isShowTitlechBox">课程列表</font>
          <!-- <span v-if="isShowMoreGoodsBox"  class="moreGoods" @click="moreCourseGoodsPage()">更多 <svg-icon icon-class="arrow-right"></svg-icon></span> -->
          <span class="choose-list-layout">
            
             <el-tooltip effect="dark" content="网格模式" placement="top">
            <span @click="selectLayoutType(1)">
              <svg-icon icon-class="grid" :className="layoutType === 1? 'active-select ':''" ></svg-icon> 
            </span>
            </el-tooltip>
            <span class="blank"></span>
           <el-tooltip effect="dark" content="列表模式" placement="top"> 
              <span  @click="selectLayoutType(0)">
                <svg-icon icon-class="item" :className="layoutType === 0? 'active-select ':''"></svg-icon>
              </span> 
            </el-tooltip> 
          </span>
        </div> 
        <!-- 列表布局--yuanmi -->
        <ul class="listitem-layout-list couseGoodsListStyle"  v-if="courseListArray.length && !Array.isArray(courseListArray[0]) && layoutType === 0">
          <li class="list-item-layout"
            v-for="(item, idx) in  courseListArray" :key="idx" @click="jumpGoodsDetailHandle(item)"
          >
            <div class="goods-poster">
              <img class="course-bg-img" :src="item.picUrl"/>
              <p class="fix-img" v-show="item.taskType">{{tagName(item.taskType)}}</p>
            </div>
            <div class="base-info-comm">
              <div class="name-tag-w">
                <p class="course-name hide">{{item.name}}</p>
                <ul class="tag-list-2">
                  <li class="tag-item hide"
                    v-for="(tag, index) in item.labelNameList"
                    :key="index"
                  >
                    <el-tooltip :content="tag" placement="top-start">
                        <div :class="item.labelNameList && item.labelNameList.length<=2? 'content-width':'text hide'">{{tag}}</div>
                    </el-tooltip>
                  </li>
                </ul>
              </div>
              <div class="price-sign-num">
                <div class="price-w">
                  <div v-if="!item.hidePriceTag">
                    <div class="goodsPrice" v-if="item.isFree"><span class="freePrice">{{setFreeString()}}</span></div>
                    <div class="goodsPrice" v-else><span class="normalPrice">¥{{item.salePrice}}</span><span class="discountPrice" v-if="item.basePrice">¥{{item.basePrice}}</span></div>
                  </div>
                </div>
                 <div class="now-sign-up">
                    <span class="signUpNum" v-if="isShowNumberBuyerBox">{{item.basicOrderNum?(item.basicOrderNum+item.purchaseNo):item.purchaseNo}}人已报名</span>             
                    <span class="nowLearnBtn">{{item.isOrder ? '立即学习' : '立即报名'}}</span>
                    <!-- <span class="nowLearnBtn" @click.stop="nowLearnHandle(isLogin, item)">{{isLogin ? '立即学习' : '立即报名'}}</span> -->
                </div> 
              </div>
            </div>
          </li>
        </ul>
        <!-- 网格布局 -->
        <ul class="grid-layout-list couseGoodsListStyle"  v-if="courseListArray.length && !Array.isArray(courseListArray[0]) && layoutType === 1">
          <li class="grid-layout-item" @click="jumpGoodsDetailHandle(item)" v-for="item in courseListArray" :key="item.id">
            <img class="course-bg-img" :src="item.picUrl"/>
            <div class="course-name hide">{{item.name}}</div>
            <div class="tag-list-w">
              <ul class="tag-list" >
                <li class="tag-item tag-active  hide"
                  v-for="(tag, index) in item.labelNameList"
                  :key="index"
                >
                  <el-tooltip :content="tag" placement="top-start">
                      <div :class="item.labelNameList && item.labelNameList.length<=2? 'content-width hide':'text hide'">{{tag}}</div>
                  </el-tooltip>
                </li>
              </ul>
            </div>
            <div class="price-signup">
              <span :class="item.isFree?'free':'sale-price'">{{item.isFree? setFreeString():`¥${item.salePrice}`}}<span class="base-price" v-show="!item.isFree">{{item.basePrice}}</span></span>
              <span class="signup-num" v-if="isShowNumberBuyerBox">{{item.basicOrderNum?(item.basicOrderNum+item.purchaseNo):item.purchaseNo}}人已经报名</span>
            </div>
            <p class="course-tag" v-show="item.taskType">{{tagName(item.taskType)}}</p>
            
          
          </li> 
        </ul> 
 
        <div class="noGoodsTips" v-if="(isShowSearchBox || isShowClassifyBox) && isShowSearchResult && !paging.total">没有搜索到相关课程哟～</div>
        <div class="nothingTips" v-if="!isShowSearchResult && !courseListArray.length">还没有课程哟～ </div>
        <!-- <div v-if="paging.currentSize > 0 && (paging.currentSize <  paging.params.pageSize)" class="daodilaTips">到底啦，更多内容即将上线</div> -->
        <!-- <paging class="paging pagingCustom" :paging="paging" v-if="!isShowOneOrManyPageBox && paging.totalPages > 1" /> -->
        <paging class="paging pagingCustom" :paging="paging" v-if="paging.total" />
      </div>
    </div>
  </div>
</template>

<script>
import paging from "../../../layout/paging";
import conf  from '../../../config/url';
import Vue from 'vue'
export default {
  name: 'courseGoods',
  components: {
    paging,

  },
  data () {
    return {
          activeArr:[],
          layoutType: 1, // 布局方式 默认列表
          firstCourseClassify:[],

          secondCourseClassify: [],

          threeCourseClassify: [],

          paging: {
              params: {
                  pageNum: 1,
                  pageSize: 20
              },
              total: 0,
              totalPages: 0,
              currentSize: 0,
          },
          typeList: [
            
              {
                type: 3,
                name: '智能排序',      
                isActive: true
              },
              {
                type: 1,
                name: '最新排序',      
                isActive: false

              },
              {
                type: 2,
                name: '人气排序',
                isActive: false

              },              

          ],
          currentType: 3,
          categoryCode: '',
          firstCategoryCode: '',
          secondCategoryCode: '',
          threeCategoryCode: '',
          goodsKey: '',
          isShowSearchResult: false,
          
          courseListArray:[[]],

          isShowSearchBox: false,

          isShowClassifyBox: false,

          isShowTitlechBox: false,

          isShowMoreGoodsBox: false,

          isShowNumberBuyerBox: false,

          isShowOneOrManyPageBox: false,

          isShowHowManyListBox: false,

          isShowManyPageManyListBox: false,

          isShowContSourceBox: false,

          isLogin: localStorage.getItem('token'),
          goodsIds:'',
          branchId:null,
          isFreePriceChangeName: false,
    }
  },

  watch:{
    'goodsKey'(newVal){
        if(!newVal){
          this.paging.params.pageNum = 1
          this.getData()
        }
    },
  },
  filters: {


  },
  created () {
    this.layoutType = this.$route.query.layoutType?this.$route.query.layoutType*1: 1
    this.theme = localStorage.getItem('theme');
    this.configMenuInfor();
    this.categoryCode = this.$route.query.categoryCode;
  },
  computed: {

  },
  mounted() {
      const configurationArr = JSON.parse( localStorage.getItem('configurationArr') )
        // 商品多个id
        this.goodsIds = configurationArr && configurationArr.filter(item => {
            if(item.key === 'goods_live_list'){
                return true
            }
        })[0];

        this.isFreePriceChangeName = configurationArr && configurationArr.filter(item => {
            if(item.key === 'is_free_price_change_name'){
                return true
            }
        })[0];

  },

  beforeDestroy() {

  },


  methods: {
    setFreeString() {
      return this.isFreePriceChangeName && this.isFreePriceChangeName.value
    },
    async getConfiguration() {
			await this.$api.configuration.getConfiguration().then((data) => {
				if(data.data.branchId&&data.data.branchId>0){
          this.branchId = data.data.branchId
        }
        this.getClassifyFindAll()
			})
		},
    tagName(type) {
      let res = ''
      if(type === 1) {
        res = '必修课'

      } else if(type === 2) {
        res = '选修课'

      } else if(type === 3) {
        res = '公开课'

      }
      return res
    },
    //
    selectLayoutType(type) {
      if(this.layoutType === type) {
        return 

      }
      this.layoutType = type
    },
    getClassifyFindAll(){
      var params = {};
      if(this.branchId){
        params.selectBranchId = this.branchId;
      }
      this.$api.coursegoods.findAll({params})
      .then(res=>{
          let allName = {
            'code': '',          
            'name': "全部",
            'showTag': true,
          }
          if (res.data && res.data.length) {
            const tempArr = res.data;
            this.activeArr = this.treeFindPath(
                tempArr,
                (data) => data.code === this.$route.query.categoryCode
            );
            this.firstCourseClassify = res.data;
            console.log('this.$route.query.code===', this.$route.query.code)
            console.log('this.$route.query.courseClassifyF===', this.$route.query.courseClassifyF)
            if (this.$route.query.code ||this.$route.query.categoryCode  || this.$route.query.courseClassifyF) {
               let currentTabIdx = 0
               this.firstCourseClassify.forEach((val, idx) => {
                  currentTabIdx = idx + 1;
                  if (val.code == this.$route.query.code || val.name == this.$route.query.courseClassifyF || val.code == this.$route.query.categoryCode) {
                      console.log('val.code == this.$route.query.code===', val.code == this.$route.query.code)
                      console.log('val.name == this.$route.query.courseClassifyF===', val.name == this.$route.query.courseClassifyF)
                      console.log(val, idx)
                      Vue.set(this.firstCourseClassify[idx], 'isActive', true);
                      this.secondCourseClassify = val.children || []
                  }
                  if(this.activeArr && this.activeArr.length==2 && this.activeArr.includes(val.code)){
                    this.clickFirstClassifyHandle(currentTabIdx,val,true);
                  }
              })
              this.firstCourseClassify.unshift(allName);   
            } else {
              this.firstCourseClassify.unshift(allName);   
              Vue.set(this.firstCourseClassify[0], 'isActive', true)
              this.categoryCode = this.firstCourseClassify[0].code;
            }
          } 
          console.log('11111-2222-3333')
          this.getData()
          
      })
    },
    treeFindPath(tree, func, path = []) {
      if (!tree) return [];
      for (const data of tree) {
          path.push(data.code);
          if (func(data)) return path;
          if (data.children) {
              const findChildren = this.treeFindPath(data.children, func, path);
              if (findChildren.length) return findChildren;
          }
          path.pop();
      }
      return [];
    },
    async clickFirstClassifyHandle(idx, item,bool) {
      this.paging.params.pageNum = 1
      this.secondCourseClassify = []
      this.threeCourseClassify = []
      this.firstCategoryCode = item.code
      this.categoryCode = this.firstCategoryCode;
      await this.getData()
      this.firstCourseClassify.forEach((val, index) => {
        if (idx == index) {
          Vue.set(val, 'isActive', true);
          if (val.children) {
              if (val.children.length == 1 && !val.children[0].showTag) {
                  this.secondCourseClassify = []
              } else {
                  this.secondCourseClassify = val.children
                  this.secondCourseClassify.forEach((el,ind) => {
                  // if (index == 0) {
                  //    Vue.set(val, 'isActive', true);
                  //  } else {
                          Vue.set(el, 'isActive', false)
                  //   }
                    if(el.code == this.$route.query.categoryCode && bool){
                      this.clickSecondClassifyHandle(ind,el)
                    }
                  })
              }





          } else {
              this.secondCourseClassify = []
              this.threeCourseClassify = []
          }

        } else {
          Vue.set(val, 'isActive', false)

        }
      })

    },

    async clickSecondClassifyHandle(idx, item) {
        this.paging.params.pageNum = 1
        this.threeCourseClassify = []
        this.secondCategoryCode = item.code
        this.categoryCode =  item.code;
        await this.getData()
        this.secondCourseClassify.forEach((val, index) => {
          if (idx == index) {
            Vue.set(val, 'isActive', true);
            if (val.children) {
                this.threeCourseClassify = val.children
                this.threeCourseClassify.forEach((val) => {
                Vue.set(val, 'isActive', false)
                })

            } else {
              this.threeCourseClassify = []
            }

          } else {
            Vue.set(val, 'isActive', false)
          }
      })

    },


    async clickThreeClassifyHandle(idx, item) {
        this.paging.params.pageNum = 1
        this.threeCategoryCode = item.code
        this.categoryCode = item.code;
        await this.getData()
        this.threeCourseClassify.forEach((val, index) => {
          if (idx == index) {
            Vue.set(val, 'isActive', true);

          } else {
            Vue.set(val, 'isActive', false)
          }
      })
 
    },


    async clickTypeSortHandle(idx, item) {
        this.paging.params.pageNum = 1
        this.typeList.forEach((val, index) => {
          if (idx == index) {
            Vue.set(val, 'isActive', true);
            this.currentType = item.type;
            this.getData()
          } else {
            Vue.set(val, 'isActive', false)
          }
        })

    },

    getData(parse) {
      return new Promise((resolve, reject)=>{
          if (parse == 'searchBtn' && this.$refs.searchBtnRef && !(this.goodsKey.trim()) ) {
              this.$message.error('请输入关键字');
              return false
          }
          if (parse == 'searchBtn') {
            this.paging.params.pageNum = 1
            this.isShowSearchResult = true
          } else {
            this.isShowSearchResult = false
          }
          const params = {
            pageNum: this.paging.params.pageNum,
            pageSize:  this.paging.params.pageSize,
          }
          const dataObj = {
            categoryCodes: this.categoryCode,
            goodsIds:this.goodsIds && this.goodsIds.value || '',// 商品id，多个逗号隔开
            type: this.currentType,
            name: encodeURIComponent(this.goodsKey),
          }
          if(this.branchId){
            dataObj.branchId = this.branchId;
          }
          // this.$api.coursegoods.findByCondition(
          //   {
          //       pageNum: this.paging.params.pageNum,
          //       pageSize:  this.paging.params.pageSize,
          //       categoryCodes: this.categoryCode,
          //       goodsIds:this.goodsIds && this.goodsIds.value || '',// 商品id，多个逗号隔开
          //       type: this.currentType,
          //       name: encodeURIComponent(this.goodsKey),
          //   }
          // )
          this.$api.coursegoods.findByConditionList(params, dataObj)
          .then(res=>{
              if (res.data && res.data.list) {
                this.courseListArray = res.data.list;
                this.paging.total = res.data.total; 
                this.paging.totalPages = res.data.pages
                this.paging.currentSize = res.data.size
                resolve(res)
              } else {
                  reject(res.message)
              }
          }).catch(err=>{
              reject(err)
              console.log('err', err)

          })
      })   

    },

    moreCourseGoodsPage() {
        this.$router.push({
          path: '/moreCourseGoods',
          query:{       
            categoryCode: this.categoryCode,
            name: this.goodsKey,
            type: this.currentType,
            pageSize: this.paging.params.pageSize,
            goodsIds:this.goodsIds && this.goodsIds.value || '',// 商品id，多个逗号隔开

          }
      })
    },

    

    async configMenuInfor(){
       this.$api.configure.configurationNew(conf.confUrl).then(res => {
                if(res.data){
                  localStorage.setItem('configurationArr',JSON.stringify(res.data.frontendConfig));
                  const configurationArr = JSON.parse(localStorage.getItem('configurationArr'))
                  configurationArr.filter( (item) => {
                      switch(item.key) {
                        case  "is_show_commodity_search":  // 是否显示商品搜索
                            this.isShowSearchBox = item.value == 'true' ? true : false
                            break;
                        case  "is_show_commodity_sort": // 是否显示商品分类及排序
                            this.isShowClassifyBox = item.value == 'true' ? true : false
                            break;

                        case  "is_show_commodity_title": // 是否显示商品列表标题
                            this.isShowTitlechBox = item.value == 'true' ? true : false
                            break;
                        case  "is_show_commodity_more_link": // 是否显示更多商品
                            this.isShowMoreGoodsBox = item.value == 'true' ? true : false
                            break;

                        case  "is_show_commodity_number_buyer_list_page":  // 是否显示XXX人已报名
                            this.isShowNumberBuyerBox = item.value == 'true' ? true : false
                            break;
                        case  "is_one_or_many_page":  // 单页展示或多页展示
                            this.isShowOneOrManyPageBox = item.value == 'true' ? true : false
                            break;
  
                        case  "how_many_commodity_default": // 默认展示多少条
                            this.paging.params.pageSize = Number(item.value) > 0 ? Number(item.value) : this.paging.params.pageSize
                            break;

                        case  "how_many_commodity_per_page":  // 每页新加载多少条（多页展示情况下）
                            //this.paging.params.pageSize =  Number(item.value) > 0 ? Number(item.value) : this.paging.params.pageSize
                            break;
                        case  "commodity_source": // 内容来源：全部商品、商品列表中选择某些商品、某些商品分类
                            this.isShowContSourceBox = item.value == 'true' ? true : false
                            break;
                        default:
                            break;
                    } 
                  });                 
                }
                this.getConfiguration();
                      
        })
    },

    jumpGoodsDetailHandle(item) {
        this.$router.push({
            path: '/courseGoodsDetail',
            query: {
                goodsId: item.id,
                goodsKind: item.goodsKind,//1 普通商品 2 分销商品 
                layoutType: this.layoutType
            }
        })   

    },

    changeInpt() {
      if (!this.goodsKey) {
        this.paging.params.pageNum = 1
        this.getData()
      }

    },


  },

};
</script>

<style lang="stylus" scoped>
 @import "../css/courseGoods.styl"
</style>
