<template>
    <div>
<p>尊敬的用户：</p>
<p>感谢您对伟东凌鸿职业人才培训平台（以下称为“我们”）的支持和信任。为了更好地为您提供服务，我们致力于保护您的个人信息并确保我们的数据安全。根据中华人民共和国网络安全法、隐私法等相关法律法规，以及伟东凌鸿职业人才培训平台的服务特点，我们制定了以下用户隐私协议授权。</p>
<p class="empower-title">1. 授权目的</p>
<p>我们收集、使用您的一些个人信息，包括姓名、电话号码、身份号码、IP地址等，目的是为了更好地为您提供服务和优化您的用户体验。我们将尽力保护您的个人信息，遵守相关法律法规，不得将您的个人信息用于其他目的。</p>
<p class="empower-title">2. 数据共享</p>
<p>我们承诺，未经您的同意，不会向第三方披露您的个人信息。但在以下情况下，我们会与东堃职业技能培训学校共享您的个人信息：
</p>
<p>（1）获得您的同意或授权；</p>
<p>（2）为了实现职业工种等级培训结业证书证明查询，需要与东堃职业技能培训学校共享您的结业证书信息（含姓名、培训职业工种及等级名、证书编号、防伪二维码（扫码查询结业证书图片）、认证时间等），用于核实培训证明颁发培训证书；</p>
<p>（3）根据法律法规的规定，向政府部门、司法机关或享有公共利益权的社会组织披露；</p>
<p>（4）为了保护我们的合法权益。</p>
<p class="empower-title">3. 数据使用限制</p>
<p>我们承诺，未经您的同意，不会将您的个人信息用于自动化决策、设备跟踪或其他类似的目的，也不会对您的个人信息进行过度依赖或使用。</p>
<p class="empower-title">4. 数据安全措施</p>
<p>我们采取了多种安全措施来保护您的个人信息，如加密技术、访问控制、数据备份等。但我们不能保证您的个人信息绝对安全，因此，您在使用我们的服务时，也应该注意保护自己的个人信息。</p>
<p class="empower-title">5. 用户权利</p>
<p>您享有访问、修改、删除您的个人信息的权利。同时，您有权要求我们停止使用您的个人信息，并向我们说明理由。</p>
<p class="empower-title">6. 隐私保护措施的实现</p>
<p>我们承诺，按照本隐私协议的约定，对您的个人信息实行严格保护。如果您有任何疑问或要求，请随时与我们联系。
</p>
<p class="empower-title">7. 协议终止</p>
<p>本协议自生效之日起生效，直至您停止使用我们的服务或我们停止提供服务。</p>
<p class="empower-title">8. 法律适用</p>
<p>本隐私协议适用中华人民共和国相关法律法规，以及我们所在地区的法律法规。</p>
<p>我们深知保护用户隐私的重要性，因此我们致力于为用户提供最良好的隐私保护。如您对本隐私协议有任何疑问，请随时与我们联系。</p>
<p class="isAgree-checkbox">
    <el-checkbox v-model="checked" @change="change">我已阅读并同意授权协议相关条款和隐私政策</el-checkbox>
</p>
</div>
</template>
<script>
export default {
    name:'Empower',
    data(){
        return{
            checked:false
        }
    },
    methods:{
        change(value){
            this.$emit('input',value)
        }
    }
}
</script>
<style lang="less" scoped>
.isAgree-checkbox{
    margin-top: 15px;
}
.empower-title{
    line-height: 24px;
    font-size: 15px;
    color: #333;
}
</style>

