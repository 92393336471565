<template>
  <div style="height:100%;position:relative;" id="tc_play_warp">
    <!-- <video id="player-container-id" width="410" height="210" preload="auto" playsinline webkit-playsinline></video> -->
  </div>
</template>

<script>
import $ from 'jquery'
import TCPlayer from 'tcplayer'
export default {
    name: 'videoraastcplayer',
    props: {
        value: {
            type: [String],
            default: ""
        },
        idName: {
            type:Number,
            default:0
        },
        isOrder:{
            type:Number,
            default:0
        }
    },
    data() {
        return {
            tcplayer: null, // 播放器实例
            options: {
                // fileID: '243791581722038521', // 请传入需要播放的视频 fileID（必须）
                // appID: '1500020443', // 请传入点播账号的 appID（必须）
                // psign: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6MTUwMDAyMDQ0MywiZmlsZUlkIjoiMjQzNzkxNTgxNzM1MDkxNDk2IiwiY3VycmVudFRpbWVTdGFtcCI6MTY4MjU2NTYwOSwiY29udGVudEluZm8iOnsiYXVkaW9WaWRlb1R5cGUiOiJPcmlnaW5hbCJ9LCJleHBpcmVUaW1lU3RhbXAiOjE2ODI2MTEyMDAsInVybEFjY2Vzc0luZm8iOnsiZG9tYWluIjoiMTUwMDAyMDQ0My52b2QyLm15cWNsb3VkLmNvbSIsInNjaGVtZSI6IkhUVFBTIn19.3zkXP4Cs-NxK9OKZ_4v1RsztZtCWDNbzXmChJmfEId8',
                autoplay: true,
                // fakeFullscreen: true,
                controlBar: {
                    progressControl: true
                }
            },
        }
    },
    destroyed() {
    },
    created() {

    },
    beforeDestroy() {},
    methods: {
        dispose() {
            this.tcplayer.dispose();
        },
        async getTcplayer(videoInfo, watchPercent, courseId, duration) {
            const timestamp = Date.now()
            $('#tc_play_warp').children().remove()
            const cover = `<video id="player-container-id_${courseId}_${timestamp}" style="width:1000px !important; height:600px !important;" preload="auto" playsinline webkit-playsinline></video>`
            $('#tc_play_warp').append(cover)
            const options = Object.assign({}, this.options)
            if (videoInfo.tencentPlayVideoResp.fileId) {
              options.fileID = videoInfo.tencentPlayVideoResp.fileId
              options.appID = videoInfo.tencentPlayVideoResp.appId
              options.psign = videoInfo.tencentPlayVideoResp.psign
            }
            if (videoInfo.tencentPlayVideoResp.playUrl) {
              options.sources = [{src: videoInfo.tencentPlayVideoResp.playUrl}]
            }
            this.tcplayer = TCPlayer(`player-container-id_${courseId}_${timestamp}`, options)
            
            // this.tcplayer.on('ended', async () => {
            //   //试看结束弹框提示立即报名
            //   this.$emit('openIt')
            // })
            if(!this.isOrder){
              var timeDisplay
              var tryTime
              var percent
              this.tcplayer?.on('play', () => {
                timeDisplay = Math.floor(this.tcplayer.currentTime());
                //试看百分比
                percent = parseFloat(watchPercent/100);
                //可试看时间
                tryTime = parseInt(duration * percent);
                // console.log('timeDisplay----',timeDisplay,'tryTime----',tryTime,'this.player._vodDuration----',this.player._vodDuration,'percent----',percent,'params.watchPercent----',params.watchPercent)
                //当时间大于试看时间时
                if(timeDisplay>tryTime){
                    //停止
                    this.tcplayer.pause(); 
                    //试看结束弹框提示立即报名
                    this.$emit('openIt')
                }else{
                    //定时器
                    clearInterval(this.timer);
                    this.timer = setInterval(()=>{
                        timeDisplay = Math.floor(this.tcplayer.currentTime());
                        // console.log('timeDisplay----',timeDisplay,'tryTime----',tryTime)
                        if(timeDisplay>=tryTime){
                            //关闭定时器
                            this.tcplayer.pause(); 
                            //清楚计时器
                            clearInterval(this.timer);
                            //试看结束弹框提示立即报名
                            this.$emit('openIt')
                        }
                    },1000);
                  }
              })
              this.tcplayer?.on('seeking', async () => {
                //移动到大于10秒自动返回
                if(this.tcplayer.currentTime()>tryTime){
                    //进度退回试看时间
                    this.tcplayer.currentTime(tryTime);
                    //试看结束弹框提示立即报名
                    this.$emit('openIt')
                }
              })
            }
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .player-container-id-dimensions {
  width: 100%;
}
/deep/ .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  border: 0; 
  background-color: transparent;
}
/deep/ .video-js .vjs-progress-control {
  top: 0;
}
</style>
