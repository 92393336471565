<template>
	<div class="taskContentItem">
		<!-- 1 线下实训 2 课程  3 考试 4  直播 5 实训  6 表单  7练习  -->
		<!-- goodsType 1 训练营 2 培训任务 -->
		<!-- 训练营课程type为1  培训任务课程type为2 -->

		<!-- 课程 -->
		<template v-if="(detailObj.goodsType == 1 && taskItem.type == 1) || (detailObj.goodsType != 1 && taskItem.type == 2)">
			<CourseDetail
				:courseDetail="taskItem"
				:detailObj="detailObj"
				:isOverType="isOverType"
				:defaultUnfoldCourse="defaultUnfoldCourse"
				:className="className"
			></CourseDetail>
		</template>

		<div :class="['task', className, { locked: taskItem.isLocked }]" v-else @click="toStudy">
			<div class="item_left">
				<div class="item_left_top">
					<span class="tag" :class="{flagTag:taskItem.type==8}">
						{{ tagList[taskItem.type] }}
					</span>
					<span class="name">{{ taskItem.type == 1 ? taskItem.stageName : taskItem.contentName }}</span>
				</div>
				<div class="item_left_bottom">
					<!-- 考试 -->
					<template v-if="taskItem.type === 3 && taskItem.isLimitTime">
						<span class="time">
							考试时间：{{ taskItem.contentStartTime && taskItem.contentStartTime.replace(/-/g, '.') }} -
							{{ taskItem.contentEndTime && taskItem.contentEndTime.replace(/-/g, '.') }}
						</span>
					</template>
					<!-- 直播 -->
					<template v-else-if="taskItem.type === 4">
						<span class="time">
							直播时间：{{ taskItem.contentStartTime && taskItem.contentStartTime.replace(/-/g, '.') }} -
							{{ taskItem.contentEndTime && taskItem.contentEndTime.replace(/-/g, '.') }}
						</span>
					</template>
					<!-- 表单 -->
					<template v-else-if="taskItem.type === 6 && taskItem.contentEndTime">
						<span class="time"> 结束时间：{{ taskItem.contentEndTime && taskItem.contentEndTime.replace(/-/g, '.') }} </span>
					</template>
					<!-- 线下实训 -->
					<template v-else-if="taskItem.type === 8 && taskItem.isTraining">
						<span class="time" v-if="taskItem.trainingResult === 0">
							成绩不合格
						</span>
						<span class="time" v-if="taskItem.trainingResult === 1">
							成绩合格
						</span>
						<span class="time" v-if="taskItem.trainingResult === 2">
							已预约
						</span>
						<span class="time" v-else>
							未预约
						</span>
					</template>
				</div>
			</div>
			<div class="item_right">
				<span class="signUp" v-if="!this.detailObj.isOrder">需要报名</span>
			</div>
		</div>
	</div>
</template>

<script>
import CourseDetail from './courseDetail'
export default {
	name: 'taskContentItem',
	data() {
		return {
			tagList: { 3: '考试', 4: '直播', 5: '线上实训', 6: '表单', 7: '练习', 8: '线下实训' },
			validity: '', // 是否显示有效期,
		}
	},
	props: {
		taskItem: Object,
		className: String,
		// 商品详情
		detailObj: {
			type: Object,
			default: () => {},
		},
		//是否过期
		isOverType: {
			type: String,
			default: 'no',
		},
		//默认展开课程id
		defaultUnfoldCourse: [Number, String],
	},
	components: {
		CourseDetail,
	},
	mounted() {
		const configurationArr = JSON.parse(localStorage.getItem('configurationArr'))
		// 是否显示训练营，任务，测评 有效期
		this.validity =
			configurationArr &&
			configurationArr.filter((item) => {
				if (item.key === 'is_show_task_term_validity') {
					return true
				}
			})[0].value
		// console.log('taskItem--------', this.taskItem)
	},
	methods: {
		toStudy() {
			if (!this.detailObj.isOrder) {
				this.$toast('报名后才可以查看')
				return
			}

			if (this.detailObj.goodsType == 1) {
				this.$router.push({
					path: '/course/catalog',
					query: {
						taskId: this.detailObj.referId, //训练营id
						taskType: this.detailObj.goodsType,
						goodsId: this.$route.query.goodsId,
						backPath: this.$route.path,
						goodsKind: this.$route.query.goodsKind,
						validity: this.validity || '',
					},
				})
			} else if (this.detailObj.goodsType == 2) {
				// this.$router.push(`/course/catalog?id=${this.detailObj.referId}&dymode=1`)
				this.$router.push({
					path: '/learning/taskDetail',
					query: {
						id: this.detailObj.referId, //培训任务id
						goodsId: this.$route.query.goodsId,
						backPath: this.$route.path,
						type: this.detailObj.goodsType,
						goodsKind: this.$route.query.goodsKind,
						validity: this.validity || '',
					},
				})
			} else if (this.detailObj.goodsType == 3) {
				this.$router.push({
					path: '/assess',
					query: {
						assessId: this.detailObj.referId, //测评id
						refId: this.$route.query.goodsId,
						refType: 2,
						goodsId: this.$route.query.goodsId,
						pathName: this.$route.path,
						goodsKind: this.$route.query.goodsKind,
					},
				})
			}
		},
	},
}
</script>

<style lang="stylus" scoped src="../css/taskContentItem.styl"></style>
